import React, { useContext, useEffect } from 'react';
import Helmet from 'react-helmet';

import { FramedContainer } from '@latitude/framed-container';
import { Heading2 } from '@latitude/heading';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import { Strong, Text } from '@latitude/text';
import { Accordion } from '@latitude/accordion';
import { List, ListItem } from '@latitude/list';
import { FeaturesSlider } from '@latitude/features-slider';
import { Link } from '@latitude/link';
import { Tel } from '@latitude/tel';
import Section from '@latitude/section';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';

import Layout from '@/components/layout';
import PageHeaderSection from '@/components/PageLayout/PageHeaderSection';

import heroImage from '../images/hero/hero-vulnerable-customers.webp';
import { PageContext } from '@/context/PageContext';

function VulnerableCustomersPage(props) {
  const contentfulPageData = useContext(PageContext);

  useEffect(() => {
    /*
     ** TODO: Parse data for page components here
     */
  }, [contentfulPageData]);

  return (
    <Layout location={props.location}>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.gemfinance.co.nz/vulnerable-customers"
          />
          <meta
            name="description"
            content="Latitude Financial Services offers simple, flexible options to help you get ahead. From loans to credit cards to insurance, you can do better. Learn more."
          />
          <title>Vulnerable Customers | Latitude Financial</title>
        </Helmet>

        <div
          className="pageHeader"
          css={`
              @media (min-width: ${BREAKPOINT.MD}) {
                && div.HeroContent {
                  align-self: center;
                }
            `}
        >
          <PageHeaderSection
            title={<>Support for vulnerable customers</>}
            subTitle={
              <>Providing you with care and support when you need it most.</>
            }
            pageImage={heroImage}
            pageImageAlt=""
          />
        </div>

        <StickyNavigation
          items={[
            {
              anchor: 'life-moments',
              label: 'Life moments'
            },
            {
              anchor: 'how-we-can-help',
              label: 'How we can help you'
            },
            {
              anchor: 'get-in-touch',
              label: 'Get in touch'
            },
            {
              anchor: 'other-support',
              label: 'Other support is available'
            }
          ]}
        />
        <Section css={{ backgroundColor: '#eee' }} id="life-moments">
          <FramedContainer
            theme={{
              frameColor: COLOR.PURPLE,
              contentBorderColor: '#DBD4FC'
            }}
          >
            <div css={{ padding: '50px' }}>
              <Heading2 css={{ textAlign: 'center', color: '#0046AA' }}>
                Here for all life&rsquo;s moments.
                <br />
                The difficult ones too.
              </Heading2>
              <Text>
                <Strong>
                  We can all become vulnerable at different points of our lives.
                  These include, but are not limited to situations of:
                </Strong>
              </Text>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  textAlign: 'left',
                  marginTop: '30px'
                }}
              >
                <List
                  dotColor={COLOR.BLACK}
                  css={`
                    && {
                      width: 100%;
                      min-width: 100%;

                      @media (min-width: ${BREAKPOINT.MD}) {
                        &&[class^='List__StyledList'] {
                          width: calc(50% - 24px);
                          min-width: inherit;
                        }
                      }
                    }
                  `}
                >
                  <ListItem>Family and domestic violence</ListItem>
                  <ListItem>Physical and mental health conditions</ListItem>
                  <ListItem>Elder abuse</ListItem>
                  <ListItem>Language barriers</ListItem>
                </List>
                <List
                  dotColor={COLOR.BLACK}
                  css={`
                    && {
                      width: 100%;
                      min-width: 100%;

                      @media (min-width: ${BREAKPOINT.MD}) {
                        &&[class^='List__StyledList'] {
                          width: calc(50% - 24px);
                          min-width: inherit;
                        }
                      }
                    }
                  `}
                >
                  <ListItem>Literacy (including financial literacy)</ListItem>
                  <ListItem>Financial distress or pressure</ListItem>
                  <ListItem>Gambling and other addictions</ListItem>
                  <ListItem>
                    Vulnerability within Indigenous communities
                  </ListItem>
                </List>
              </div>
            </div>
          </FramedContainer>
        </Section>

        <Section
          id="how-we-can-help"
          css={`
            && h4.lfs-heading {
              color: #0046aa;
            }
            && div.lfs-card-icon {
              background-color: #f8f8f8;
              width: 100px !important;
              height: auto !important;
              padding: 10px;
              border-radius: 50px;
            }
            && div.lfs-card-icon svg {
              width: 80px !important;
              height: 80px !important;
            }
          `}
        >
          <FeaturesSlider
            heading="How we can help you"
            subheading={
              <>
                If you're facing a challenging personal situation, you're not
                alone.
                <br />
                Talk to us so we can help or advise you of other support.
              </>
            }
            data={[
              {
                icon: 'icon-cant-pay',
                title: 'Hardship Care',
                text:
                  "If you're struggling with your Latitude repayments, we're here to help."
              },
              {
                icon: 'icon-ear',
                title: 'Hearing impaired?',
                text:
                  'We use the <a href="https://www.nzrelay.co.nz/" target="_blank" rel="noopener noreferrer">NZ Relay Service</a> to ensure we are contactable for customers with a hearing or speech impairment.'
              },
              {
                icon: 'icon-hand-shake',
                title: 'Authorised Third Parties',
                text:
                  'If you would like Latitude to engage with an Authorised Third Party, please advise our Customer Specialists.'
              }
            ]}
          />
        </Section>

        <Section
          id="get-in-touch"
          css={`
            background-color: #eff7ff;
            && div.lfs-card {
              text-align: center;
            }
            && div.lfs-card-icon {
              background-color: #fff;
              width: 100px !important;
              height: auto !important;
              padding: 10px;
              border-radius: 50px;
            }
            && div.lfs-card-icon svg {
              width: 80px !important;
              height: 80px !important;
            }
          `}
        >
          <FeaturesSlider
            heading="Get in touch"
            data={[
              {
                icon: 'icon-cant-pay',
                title: 'Request financial hardship help',
                jsx: (
                  <>
                    If you're having trouble making your repayments, you can
                    request help.
                    <br />
                    <br />
                    <Link href="/hardship-care/">Apply for Hardship Care</Link>
                  </>
                )
              },
              {
                icon: 'icon-iphone2',
                title: 'Call our Hardship Care team',
                jsx: (
                  <>
                    Mon to Fri, 8:30am &ndash; 5:00pm
                    <br />
                    <Tel no="0800 433 033" />
                  </>
                )
              },
              {
                icon: 'icon-mail-sent',
                title: 'Mail our Hardship Care team',
                jsx: (
                  <>
                    Latitude Hardship Care
                    <br />
                    PO Box 4058
                    <br />
                    Shortland Street
                    <br />
                    Auckland 1140
                  </>
                )
              },
              {
                icon: 'icon-chat',
                title: 'Customer Service',
                jsx: (
                  <>
                    Mon to Fri, 9:00am &ndash; 6:30pm
                    <br />
                    <Tel no="0800 500 505" />
                  </>
                )
              }
            ]}
          />
        </Section>

        <Section css={{ backgroundColor: '#F8F8F8' }} id="other-support">
          <FramedContainer
            theme={{
              frameColor: '#E5E5E5',
              contentBorderColor: '#E5E5E5'
            }}
          >
            <div css={{ padding: '50px' }}>
              <Heading2 css={{ color: '#0046AA', textAlign: 'center' }}>
                Other support is available
              </Heading2>
              <Text>
                We train Latitude staff to recognise and support customers who
                are vulnerable including treating you with empathy and respect.
                In addition to our team, who can refer you to external support,
                we have also listed a number of support services that you can
                access below.
                <br />
                <Strong>
                  <ul>
                    <li>105 is used for Police Non-emergency</li>
                    <li>If someone is in immediate danger, contact: 111</li>
                  </ul>
                </Strong>
              </Text>
              <Accordion
                className="mt-5"
                titleBgColor="grey-light"
                items={[
                  {
                    id: 'mental-health',
                    title: 'Mental health',
                    content: (
                      <div css={{ textAlign: 'left', padding: '20px' }}>
                        If you are experiencing mental health issues, you can
                        call the groups below to have a confidential discussion:
                        <List
                          dotColor={COLOR.BLACK}
                          css={{ paddingLeft: '40px' }}
                        >
                          <ListItem>
                            <Link
                              href="https://mentalhealth.org.nz/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Mental Health Foundation
                            </Link>
                          </ListItem>
                        </List>
                      </div>
                    )
                  },
                  {
                    id: 'domestic-violence',
                    title: 'Family and domestic violence',
                    content: (
                      <div css={{ textAlign: 'left', padding: '20px' }}>
                        If you are experiencing family and domestic violence or
                        abuse, you can call the groups below for a confidential
                        discussion:
                        <List
                          dotColor={COLOR.BLACK}
                          css={{ paddingLeft: '40px' }}
                        >
                          <ListItem>
                            <Link
                              href="https://www.areyouok.org.nz/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Are you ok?
                            </Link>
                          </ListItem>
                          <ListItem>
                            <Link
                              href="https://2shine.org.nz/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Shine
                            </Link>
                          </ListItem>
                          <ListItem>
                            <Link
                              href="https://womensrefuge.org.nz/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Women's Refuge
                            </Link>
                          </ListItem>
                        </List>
                      </div>
                    )
                  },
                  {
                    id: 'elder-abuse',
                    title: 'Elder abuse',
                    content: (
                      <div css={{ textAlign: 'left', padding: '20px' }}>
                        If you are experiencing elder abuse, you can call the
                        organisation below for a confidential discussion:
                        <List
                          dotColor={COLOR.BLACK}
                          css={{ paddingLeft: '40px' }}
                        >
                          <ListItem>
                            <Link
                              href="https://www.msd.govt.nz/what-we-can-do/seniorcitizens/index.html"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Super Seniors (Ministry of Social Development)
                            </Link>
                          </ListItem>
                        </List>
                      </div>
                    )
                  },
                  {
                    id: 'support-indigenous',
                    title: 'Support for M\u0101ori and Pasifika communities',
                    content: (
                      <div css={{ textAlign: 'left', padding: '20px' }}>
                        <List
                          dotColor={COLOR.BLACK}
                          css={{ paddingLeft: '40px' }}
                        >
                          <ListItem>
                            <Link
                              href="https://thefono.org/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              The Fono
                            </Link>
                          </ListItem>
                          <ListItem>
                            <Link
                              href="https://www.southseas.org.nz/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Southseas Healthcare
                            </Link>
                          </ListItem>
                          <ListItem>
                            <Link
                              href="https://nsbs.co.nz/client_partners/maori-and-pasifika/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              NSBS
                            </Link>
                          </ListItem>
                        </List>
                      </div>
                    )
                  },
                  {
                    id: 'financial-distress',
                    title: 'Financial distress or pressure',
                    content: (
                      <div css={{ textAlign: 'left', padding: '20px' }}>
                        If you are experiencing financial distress, you can call
                        the groups below for a confidential discussion:
                        <List
                          dotColor={COLOR.BLACK}
                          css={{ paddingLeft: '40px' }}
                        >
                          <ListItem>
                            <Link
                              href="https://www.moneytalks.co.nz/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Money Talks
                            </Link>
                          </ListItem>
                          <ListItem>
                            <Link
                              href="https://www.fincap.org.nz/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              FinCap
                            </Link>
                          </ListItem>
                        </List>
                      </div>
                    )
                  },
                  {
                    id: 'gambling',
                    title: 'Gambling',
                    content: (
                      <div css={{ textAlign: 'left', padding: '20px' }}>
                        If you're experiencing difficulties with gambling and
                        need support, contact:
                        <List
                          dotColor={COLOR.BLACK}
                          css={{ paddingLeft: '40px' }}
                        >
                          <ListItem>
                            <Link
                              href="https://gamblinghelpline.co.nz/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Gambling Helpline NZ
                            </Link>
                          </ListItem>
                          <ListItem>
                            <Link
                              href="https://www.pgf.nz/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              PGF Services
                            </Link>
                          </ListItem>
                        </List>
                      </div>
                    )
                  }
                ]}
              />
            </div>
          </FramedContainer>
        </Section>
      </main>
    </Layout>
  );
}

export default VulnerableCustomersPage;
